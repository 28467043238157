import { useEffect } from 'react'
import { initLogger } from 'shared'
import { create } from 'zustand'

const logger = initLogger('App Updater')

interface UpdateAppState {
  hasUpdate: boolean
  setHasUpdate: (hasUpdate: boolean) => void

  hasUnrecoverableAblyState: boolean
  setHasUnrecoverableAblyState: (hasUnrecoverableAblyState: boolean) => void
}

export const useAppUpdateStore = create<UpdateAppState>((set) => ({
  hasUpdate: false,
  setHasUpdate: (hasUpdate: boolean) => set(() => ({ hasUpdate })),

  hasUnrecoverableAblyState: false,
  setHasUnrecoverableAblyState: (hasUnrecoverableAblyState: boolean) =>
    set(() => ({ hasUnrecoverableAblyState })),
}))

export const useUpdateApp = (
  {
    canUpdate,
    beforeUpdate,
  }: {
    canUpdate?: boolean
    beforeUpdate?: (...args: Array<unknown>) => unknown
  } = { canUpdate: true }
) => {
  const store = useAppUpdateStore()

  useEffect(() => {
    const shouldUpdate = store.hasUpdate || store.hasUnrecoverableAblyState

    if (canUpdate && shouldUpdate) {
      ;(async () => {
        logger.info(
          {
            hasUpdate: store.hasUpdate,
            hasUnrecoverableAblyState: store.hasUnrecoverableAblyState,
          },
          'Updating App'
        )
        await (async () => beforeUpdate?.())().catch(() => {})
        window.location.reload()
      })()
    }
  }, [
    beforeUpdate,
    canUpdate,
    store.hasUnrecoverableAblyState,
    store.hasUpdate,
  ])
}
