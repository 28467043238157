import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'
import { $Enums } from 'api'

import { bus } from '../lib/bus.ts'

export const isConfigValidQueryOptions = (
  types: Array<$Enums.TipologiaTotem>
) => {
  return queryOptions({
    queryKey: ['config', 'isValid'],
    queryFn: async () => bus.isConfigValid(types),
  })
}

export const useIsConfigValid = (
  types: Array<$Enums.TipologiaTotem>,
  options?: Partial<ReturnType<typeof isConfigValidQueryOptions>>
) => {
  return useQuery({
    ...isConfigValidQueryOptions(types),
    ...options,
  })
}

export const configQueryOptions = () => {
  return queryOptions({
    queryKey: ['config'],
    queryFn: async () => bus.getConfig(),
  })
}

export const useConfig = () => {
  return useQuery(configQueryOptions())
}

export const useUpdateConfig = () => {
  return useMutation<unknown, unknown, Record<string, unknown>>({
    mutationFn: (data) => bus.updateConfig(data),
  })
}
