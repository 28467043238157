import { useAppUpdateStore } from '@/hooks/useUpdateApp.ts'

import { registerServiceWorker } from './registerServiceWorker.ts'

const swUrl = `/${
  import.meta.env.MODE === 'production' ? 'sw.js' : 'dev-sw.js?dev-sw'
}`

export const initServiceWorker = () =>
  registerServiceWorker({
    url: swUrl,
    onUpdateFound: () => {
      useAppUpdateStore.setState({ hasUpdate: true })
    },
    updatePollingIntervalSeconds: 60 * 2, // 2 minutes
  })
