import { queryOptions, useQuery } from '@tanstack/react-query'

import { apiClient } from '../apiClient'

export const eliminacodeQueryOptions = (ambulatorioId?: string) =>
  queryOptions({
    queryKey: ['eliminacode', 'activate', ambulatorioId],
    queryFn: () =>
      apiClient.api.eliminacode[':ambulatorioId']
        .$get({
          param: { ambulatorioId: ambulatorioId! },
        })
        .then((r) => r.json()),
  })

export const useEliminacode = (
  ambulatorioId?: string,
  options?: Partial<ReturnType<typeof eliminacodeQueryOptions>>
) =>
  useQuery({
    ...eliminacodeQueryOptions(ambulatorioId),
    ...options,
  })
