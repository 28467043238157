import { useEffect } from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'

export const Route = createFileRoute('/eliminacode/$ambulatorioId/')({
  component: AmbulatorioIndex,
})

function AmbulatorioIndex() {
  const params = Route.useParams()
  const navigate = useNavigate()

  useEffect(() => {
    navigate({
      to: '/eliminacode/$ambulatorioId/1-instructions',
      params,
    })
  }, [navigate, params])

  return null
}
