import { useEffect } from 'react'
import { createFileRoute, Outlet } from '@tanstack/react-router'

import { useEliminacode } from '@/hooks/eliminacode.ts'
import { useKioskStatus } from '@/hooks/useKioskStatus.ts'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'

// import { OfflineWarning } from '@/components/OfflineWarning.tsx'

export const Route = createFileRoute('/eliminacode/$ambulatorioId')({
  component: EliminacodeIndex,
})

function EliminacodeIndex() {
  useKioskStatus({ performRedirect: true })
  const { ambulatorioId } = Route.useParams()
  useEliminacode(ambulatorioId, {
    gcTime: 0,
    staleTime: 0,
  })

  useEffect(() => {
    if (import.meta.env.DEV) return

    document.oncontextmenu = () => false

    return () => {
      document.oncontextmenu = () => true
    }
  }, [])

  return (
    <>
      <DefaultLayout>
        <Outlet />

        {/*<OfflineWarning />*/}
      </DefaultLayout>
    </>
  )
}
