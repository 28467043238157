import { useCallback, useEffect } from 'react'
import { getRouteApi } from '@tanstack/react-router'

import {
  useTimeoutCounter,
  UseTimeoutCounterOptions,
} from '@/hooks/useTimeoutCounter.ts'

export type UseSessionTimeoutOptions = UseTimeoutCounterOptions & {
  enabled?: boolean
}

const route = getRouteApi('/eliminacode/$ambulatorioId')

export const useSessionTimeout = (options: UseSessionTimeoutOptions) => {
  const { enabled, ...timeoutOptions } = { enabled: true, ...options }
  const params = route.useParams()
  const navigate = route.useNavigate()
  const timeoutCounter = useTimeoutCounter()
  const expireCallback = useCallback(
    () => navigate({ to: '/eliminacode/$ambulatorioId', params }),
    [navigate, params]
  )
  const startTimeout = useCallback(
    () => timeoutCounter.enable(timeoutOptions, () => expireCallback()),
    [expireCallback, JSON.stringify(timeoutOptions)]
  )

  useEffect(() => {
    if (enabled) {
      startTimeout()
    }
  }, [enabled, startTimeout])

  return { timeoutCounter, startTimeout }
}
