import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { bus } from '@/lib/bus.ts'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx'
import { Input } from '@/components/ui/input'
import { SubmitButton } from '@/components/SubmitButton.tsx'

const formSchema = z.object({
  password: z.string(),
})

export function AuthenticateModal(props: {
  isOpen?: boolean
  onClose?: () => void
}) {
  const { isOpen, onClose } = props
  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onClose?.()
    }
  }

  const form = useForm<Partial<z.infer<typeof formSchema>>>({
    values: {},
    resolver: zodResolver(formSchema),
  })

  const stopKioskModeMutation = useMutation<
    unknown,
    Error,
    { password: string }
  >({
    mutationFn: (variables) => bus.stopKioskMode(variables.password),
    onError() {
      form.setError('password', {
        type: 'custom',
        message: 'Password non valida',
      })
    },
  })

  const navigate = useNavigate()
  const onSubmit = async () => {
    await stopKioskModeMutation.mutateAsync({
      password: form.watch().password!,
    })

    navigate({ to: '/getting-started/3-configuration' })

    onClose?.()
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Autenticazione</DialogTitle>
          <DialogDescription>
            Per favore, inserisci la password per sbloccare la sessione
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="w-100 flex flex-col space-y-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            {/*<div className="grid gap-4 py-4">*/}
            {/*  <div className="grid grid-cols-4 items-center gap-4">*/}
            {/*    <Label htmlFor="password" className="text-right">*/}
            {/*      Password*/}
            {/*    </Label>*/}
            {/*    <Input id="password" className="col-span-3" />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="flex">
              <Button
                className="w-full"
                variant="secondary"
                type="button"
                onClick={onClose}
              >
                Chiudi
              </Button>
              <SubmitButton
                disabled={!form.formState.isValid}
                className="w-full"
                type="submit"
              >
                Autentica
              </SubmitButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
