import { useQueryClient } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { ArrowRightIcon, CogIcon } from 'lucide-react'

import { bus } from '@/lib/bus.ts'
import { configQueryOptions } from '@/hooks/store.ts'

import { Button } from '../../components/ui/button'

export const Route = createFileRoute(
  '/getting-started/4-configuration-completed'
)({
  component: ConfigurationCompleted,
})

export default function ConfigurationCompleted() {
  const queryClient = useQueryClient()

  const handleStartKioskSessionClick = async () => {
    queryClient.invalidateQueries({
      queryKey: configQueryOptions().queryKey,
    })
    await bus.startKioskMode()
  }

  return (
    <>
      <div className="container relative flex h-full flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
            <div className="space-y-2 text-center">
              <h1 className="mb-4 text-2xl font-semibold tracking-tight">
                Configurazione Completata!
              </h1>
              <div className="flex flex-col">
                <Link to={'/'} className="mb-10">
                  <Button
                    className="w-full"
                    onClick={handleStartKioskSessionClick}
                  >
                    Inizia Sessione Kiosk{' '}
                    <ArrowRightIcon className="ml-2 size-4" />
                  </Button>
                </Link>

                <Link
                  to={'/getting-started/3-configuration'}
                  className="w-full"
                >
                  <Button variant="link" className="w-full">
                    Torna alla configurazione{' '}
                    <CogIcon className="ml-2 size-4" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
