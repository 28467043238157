import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from '@tanstack/react-router'

import { useLicense } from '@/hooks/license.ts'
import { useIsConfigValid } from '@/hooks/store.ts'
import { useGetToken } from '@/hooks/token.ts'

export const useKioskStatus = ({
  performRedirect,
}: {
  performRedirect: boolean
}) => {
  const tokenQuery = useGetToken()
  const licenseQuery = useLicense({ enabled: !!tokenQuery.data })
  const license = licenseQuery.data
  const licenseTypes = useMemo(
    () => (licenseQuery.data?.success ? licenseQuery.data.data?.types : []),
    [license]
  )
  const isConfigValidQuery = useIsConfigValid(licenseTypes, {
    enabled: licenseQuery.isSuccess,
  })
  const navigate = useNavigate()
  const [initialCheckPerformed, setInitialCheckPerformed] = useState(false)

  const status = useMemo(() => {
    const dataReady =
      tokenQuery.fetchStatus === 'idle' &&
      licenseQuery.fetchStatus === 'idle' &&
      isConfigValidQuery.fetchStatus === 'idle'

    if (!dataReady) {
      return 'pending'
    }

    if (!tokenQuery.data || licenseQuery.isError) {
      return 'needs-auth'
    }

    if (tokenQuery.data && isConfigValidQuery.data) {
      return 'ready'
    }

    if (!isConfigValidQuery.data) {
      return 'needs-config'
    }

    return null
  }, [tokenQuery, licenseQuery, isConfigValidQuery])

  useEffect(() => {
    if (!performRedirect || initialCheckPerformed) return
    if (status === 'ready') {
      setInitialCheckPerformed(true)
    }

    if (status === 'needs-auth') {
      setInitialCheckPerformed(true)
      navigate({ to: '/getting-started/1-authenticate' })
    }

    if (status === 'needs-config') {
      setInitialCheckPerformed(true)
      navigate({
        to: '/getting-started/3-configuration',
        search: { types: licenseTypes },
      })
    }
  }, [status, licenseTypes, initialCheckPerformed, navigate, performRedirect])

  return { status }
}
