import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import * as Ably from 'ably'
import { initLogger } from 'shared'

const logger = initLogger('ably')

interface AblyContextProps {
  ably?: Ably.Realtime | null
  isConnected?: boolean | undefined
  connectionState?: Ably.ConnectionState
  stateChangeHistory?: Ably.ConnectionState[]
  disconnect: () => void
  setAuthCallback: (authCallback: Ably.AuthOptions['authCallback']) => void
}

interface AblyProviderProps {
  authCallback?: Ably.AuthOptions['authCallback']
  children?: React.ReactNode
}

export const AblyContext = createContext<AblyContextProps>({
  ably: null,
  disconnect: () => {},
  setAuthCallback: () => {},
})

export const AblyProvider: React.FC<AblyProviderProps> = ({
  authCallback: initialAuthCallback,
  children,
}) => {
  const [ably, setAbly] = useState<Ably.Realtime | null>(null)
  const [authCallback, setAuthCallback] = useState<
    Ably.AuthOptions['authCallback'] | undefined
  >(initialAuthCallback)
  const [connectionState, setConnectionState] = useState<
    Ably.ConnectionState | undefined
  >(ably?.connection?.state)
  const isConnected = useMemo(
    () => connectionState === 'connected',
    [connectionState]
  )
  const [stateChangeHistory, setStateChangeHistory] = useState<
    Ably.ConnectionState[]
  >([])

  const handleConnectionStateChange = useCallback(
    (stateChange: Ably.ConnectionStateChange) => {
      logger.info({ stateChange }, 'Ably connection state change')
      setConnectionState(stateChange.current)
      setStateChangeHistory((history) =>
        [stateChange.current, ...history].slice(0, 10)
      )
    },
    []
  )

  useEffect(() => {
    if (authCallback) {
      const ablyInstance = new Ably.Realtime({
        authCallback,
        recover: (_, cb) => cb(true),
      })
      setAbly(ablyInstance)
    } else {
      setAbly((ably) => {
        ably?.close()
        return null
      })
    }
  }, [authCallback])

  useEffect(() => {
    if (ably) {
      ably.connection.on(handleConnectionStateChange)
      return () => ably.connection.off(handleConnectionStateChange)
    }
  }, [ably, handleConnectionStateChange])

  const disconnect = useCallback(() => {
    ably?.close()
  }, [ably])

  const value = useMemo(
    () => ({
      ably,
      isConnected,
      connectionState,
      stateChangeHistory,
      disconnect,
      setAuthCallback: (a: typeof authCallback) => setAuthCallback(() => a),
    }),
    [ably, isConnected, connectionState, stateChangeHistory, disconnect]
  )

  return <AblyContext.Provider value={value}>{children}</AblyContext.Provider>
}
