import { useContext, useEffect } from 'react'
import * as Ably from 'ably'
import { InboundMessage, messageCallback } from 'ably'
import { initLogger } from 'shared'

import { AblyContext } from './context.tsx'

const logger = initLogger('ably')

export type Message<MessageData = object> = InboundMessage & {
  data: MessageData
}

export interface UseChannelArgs<M extends Message> {
  channels: { channelName: string; eventName?: string }[]
  cb: messageCallback<M>
  options?: {
    onRecover?: () => void
    onConnected?: () => void
  }
}

export interface UseAblyResult {
  connectionState?: Ably.ConnectionState
  stateChangeHistory?: Ably.ConnectionState[]
  isConnected?: boolean
  disconnect: () => void
}

export const useAblyEvent = <MessageType extends Message>(
  channels: UseChannelArgs<MessageType>['channels'],
  cb: UseChannelArgs<MessageType>['cb'],
  options?: UseChannelArgs<MessageType>['options']
): UseAblyResult => {
  const { ably, isConnected, connectionState, stateChangeHistory, disconnect } =
    useContext(AblyContext)

  useEffect(() => {
    if (!ably) return

    const cleanupFunctions = channels.map(({ channelName, eventName }) => {
      const channel = ably.channels.get(channelName)
      const handler = (stateChange: Ably.ChannelStateChange) => {
        logger.info({ channelName, stateChange }, 'Channel state change')
      }

      channel.on(handler)
      if (eventName) {
        channel.subscribe(eventName, cb as messageCallback<InboundMessage>)
        return () => {
          channel.unsubscribe(eventName, cb as messageCallback<InboundMessage>)
          channel.off(handler)
        }
      } else {
        channel.subscribe(cb as messageCallback<InboundMessage>)
        return () => {
          channel.unsubscribe(cb as messageCallback<InboundMessage>)
          channel.off(handler)
        }
      }
    })

    return () => cleanupFunctions.forEach((cleanup) => cleanup())
  }, [ably, channels, cb])

  useEffect(() => {
    if (
      stateChangeHistory?.[0] === 'connected' &&
      stateChangeHistory?.[2] &&
      options?.onRecover
    ) {
      logger.info({ stateChangeHistory }, 'Calling onRecover')
      options.onRecover?.()
    }
  }, [stateChangeHistory, options])

  return {
    connectionState,
    stateChangeHistory,
    isConnected,
    disconnect,
  }
}

export const useAbly = (): UseAblyResult & {
  setAuthCallback: (authCallback: Ably.AuthOptions['authCallback']) => void
  ably?: Ably.Realtime | null
} => {
  const {
    isConnected,
    connectionState,
    stateChangeHistory,
    disconnect,
    setAuthCallback,
    ably,
  } = useContext(AblyContext)

  return {
    connectionState,
    stateChangeHistory,
    isConnected,
    disconnect,
    setAuthCallback,
    ably,
  }
}

export const usePresence = (channelName: string, data?: unknown) => {
  const { isConnected, ably } = useAbly()

  useEffect(() => {
    const channel = ably?.channels?.get(channelName)
    if (isConnected && channel) {
      channel.presence.enter()

      return () => {
        channel.presence.leave()
      }
    }
  }, [isConnected, ably])

  useEffect(() => {
    const channel = ably?.channels?.get(channelName)
    if (isConnected && channel && data) {
      channel.presence.update(data)
    }
  }, [isConnected, JSON.stringify(data)])
}
