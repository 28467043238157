import React from 'react'

import { cn } from '@/lib/utils'
import { Button, ButtonProps } from '@/components/ui/button'
import { Touchable } from '@/components/Touchable.tsx'

export interface SelectionButtonProps
  extends Omit<ButtonProps, 'size' | 'variant'> {
  size?: 'sm' | 'md' | 'lg'
  variant?: 'primary' | 'secondary' | 'muted' | 'danger'
}

export const SelectionButton: React.FC<SelectionButtonProps> = (props) => {
  const { size, variant, ...rest } = props

  return (
    <Touchable>
      <Button
        {...rest}
        className={cn(
          'flex h-auto max-h-96 grow cursor-pointer flex-col items-start justify-around bg-gradient-to-r !leading-relaxed tracking-wide',
          variant === 'primary' &&
            'from-blue-600 to-violet-600 hover:from-violet-700 hover:to-blue-800',
          variant === 'secondary' &&
            'from-green-400 via-green-500 to-green-600 hover:from-green-700 hover:to-green-500',
          variant === 'muted' &&
            'from-[#729fbe] to-[#3d698c] hover:from-[#325572] hover:to-[#5083a7]',
          variant === 'danger' &&
            'from-orange-500 to-red-500 hover:from-red-700 hover:to-orange-700',

          size === 'lg' &&
            'px-10 py-2 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl',
          size === 'md' &&
            'px-4 py-2 sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl',
          size === 'sm' &&
            'px-4 py-2 sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl',

          props.className
        )}
      />
    </Touchable>
  )
}

SelectionButton.defaultProps = { variant: 'primary', size: 'lg' }
