import { useEffect } from 'react'
import { initLogger } from 'shared'

interface UseCardScanParams {
  onScan: (scannedData: string, matchedPattern: RegExp | null) => void
  patterns: RegExp[]
  inputTimeout?: number // Time in ms to wait for additional input before considering the sequence complete
}

const logger = initLogger('CardScan')

export const useCardScan = ({
  onScan,
  patterns,
  inputTimeout = 50,
}: UseCardScanParams): void => {
  useEffect(() => {
    let inputBuffer: string[] = []
    let bufferTimeout: ReturnType<typeof setTimeout> | null = null

    const processInput = () => {
      const scannedInput = inputBuffer.join('')
      const matchedPattern = patterns.find((pattern) =>
        pattern.test(scannedInput)
      )

      logger.info({ scannedInput, matchedPattern }, 'Scan performed')
      onScan(scannedInput, matchedPattern || null)

      // Reset the buffer and timeout after processing
      inputBuffer = []
      bufferTimeout = null
    }

    const handleKeyPress = (e: KeyboardEvent) => {
      const char = e.key

      // Only add character keys to the buffer
      if (char.length === 1) {
        inputBuffer.push(char)
      }

      // Clear the existing timeout
      if (bufferTimeout) clearTimeout(bufferTimeout)

      // Reset the timeout to process input after a period of inactivity
      bufferTimeout = setTimeout(processInput, inputTimeout)
    }

    document.addEventListener('keypress', handleKeyPress)

    return () => {
      document.removeEventListener('keypress', handleKeyPress)

      // Ensure to clear the timeout when the component unmounts to prevent memory leaks
      if (bufferTimeout) clearTimeout(bufferTimeout)
    }
  }, [onScan, patterns, inputTimeout])
}

// Note: This example might not work as expected in all browsers due to security restrictions on synthetic events.
const simulateCardScan = (input: string) => {
  setTimeout(() => {
    input.split('').forEach((char) => {
      const eventInitDict = {
        key: char,
        bubbles: true,
        cancelable: true,
      }
      const event = new KeyboardEvent('keypress', eventInitDict)
      document.dispatchEvent(event)
    })
  }, 1000)
}

// @ts-expect-error for debug
window.simulateCardScan = simulateCardScan
