import { useCallback, useState } from 'react'

export const useModalState = (initialState = false) => {
  const [isOpen, setOpen] = useState(initialState)
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen])
  const open = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])

  return { isOpen, setOpen, toggle, open, close }
}
