import React from 'react'
import { Slot } from '@radix-ui/react-slot'

import { cn } from '@/lib/utils'

export type BigTextProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  size?: 'sm' | 'md' | 'lg'
  asChild?: boolean
}

export const BigText: React.FC<BigTextProps> = (props) => {
  const size = props.size ?? 'lg'
  const Comp = props.asChild ? Slot : 'div'

  return (
    <Comp
      {...props}
      className={cn(
        'font-bold !leading-tight text-gray-900',
        size === 'lg' &&
          'sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl',
        size === 'md' &&
          'sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl',
        size === 'sm' &&
          'sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl',
        'className' in props && props.className
      )}
    />
  )
}
