import * as React from 'react'
import { useCallback } from 'react'

import buttonSound from '@/assets/sounds/button-sound.mp3'

const buttonSoundAudio = new Audio(buttonSound)
buttonSoundAudio.preload = 'auto'

export const Touchable = React.forwardRef<
  unknown,
  { children: React.ReactNode }
>(({ children }, ref) => {
  const handleClick = useCallback(
    (e: unknown) => {
      buttonSoundAudio.play()

      // @ts-expect-error cheeky
      if (children?.props?.onClick) {
        // @ts-expect-error cheeky
        children?.props?.onClick(e)
      }
    },
    [children]
  )

  // @ts-expect-error fine
  return React.cloneElement(children, { ref, onClick: handleClick })
})

Touchable.displayName = 'Touchable'
