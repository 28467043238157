import { $Enums } from 'api'
import { Bus, Config, configSchema } from 'shared'

const configKey = 'aspTotems.config'
const tokenKey = 'aspTotems.token'

const safeJsonParse = (str?: string | null) => {
  if (typeof str !== 'string') return null

  try {
    return JSON.parse(str)
  } catch (err) {
    return null
  }
}

export const bus: Bus = window.bus ?? {
  getConfig() {
    return safeJsonParse(localStorage.getItem(configKey))
  },
  isConfigValid(types?: Array<$Enums.TipologiaTotem>) {
    const config = safeJsonParse(localStorage.getItem(configKey))

    return configSchema.safeParse({ ...config, types }).success
  },
  updateConfig(data: Partial<Config>) {
    localStorage.setItem(configKey, JSON.stringify(data))
  },

  getPrinters() {
    return []
  },
  sendTestPrint(printerName: string) {
    alert(`print! ${printerName}`)
  },
  sendPdfBufferToPrint(content: Buffer): Promise<void> | void {
    const pdfBlob = new Blob([content], { type: 'application/pdf' })
    const objectURL = URL.createObjectURL(pdfBlob)

    printPDF(objectURL)
  },

  getToken() {
    return safeJsonParse(localStorage.getItem(tokenKey))
  },
  setToken(token: string) {
    localStorage.setItem(tokenKey, JSON.stringify(token))
  },

  async startKioskMode() {
    return this.updateConfig({
      ...(await this.getConfig()),
      mode: 'kiosk',
    })
  },

  async stopKioskMode() {
    await this.updateConfig({
      ...(await this.getConfig()),
      mode: 'configuration',
    })
  },
}

if (!window.bus) {
  window.bus = bus
}

declare global {
  interface Window {
    bus: Bus
  }
}

function printPDF(objectURL: string) {
  // Create an iframe element
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none' // Hide the iframe

  // Event listener for when the iframe is loaded
  iframe.onload = function () {
    setTimeout(() => {
      // Give it time to load the PDF
      iframe.contentWindow?.print() // Invoke the print dialog

      // Optional: Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe)
      }, 10000)
    }, 100)
  }

  iframe.src = objectURL
  document.body.appendChild(iframe) // Add the iframe to the document
}
