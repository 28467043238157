import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 60 * 1000,
      staleTime: 60 * 1000,
    },
  },
})

// @ts-expect-error debug
window.queryClient = queryClient
