import { initLogger } from 'shared'

const logger = initLogger('UpdateChecker')

/**
 * UpdateChecker is responsible for checking for new versions of the UI
 */
export default class UpdateChecker {
  pollingIntervalSeconds: number
  swRegistration?: ServiceWorkerRegistration
  isPollingRegistered: boolean

  constructor({ pollingInterval }: { pollingInterval?: number }) {
    this.pollingIntervalSeconds = pollingInterval || (3600 / 4) * 1000
    this.isPollingRegistered = false
  }

  setSwRegistration(swRegistration: ServiceWorkerRegistration) {
    this.swRegistration = swRegistration
  }

  /**
   * Method to register the interval that regularly checks for UI updates
   * preferred method is service worker, it fall back to `release.json` file checking
   */
  setupPolling() {
    if (this.isPollingRegistered) return

    const check = () => {
      if (this.swRegistration) {
        logger.info('checking for service worker update')
        return this.swRegistration.update()
      }

      logger.info('service worker not supported')
    }

    logger.info(`setting up Service worker polling`)
    setInterval(() => check(), this.pollingIntervalSeconds * 1000)
    this.isPollingRegistered = true
  }
}
