import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/it'

dayjs.extend(localizedFormat)

dayjs.locale('it')

export { dayjs }
