import * as React from 'react'
import { useMemo } from 'react'
import { LoaderCircleIcon } from 'lucide-react'

import { cn } from '../lib/utils'
import { Button, ButtonProps, buttonVariants } from './ui/button'

export type SubmitButtonProps = ButtonProps & {
  isSubmitting?: boolean
  isValid?: boolean
}

export const SubmitButton = React.forwardRef<
  HTMLButtonElement,
  SubmitButtonProps
>(({ className, variant, size, ...props }, ref) => {
  const { isSubmitting, isValid, ...buttonProps } = props
  const disabled = useMemo(() => {
    if (isSubmitting) return true

    if (typeof isValid === 'boolean') return !isValid

    return props.disabled
  }, [props.disabled, isSubmitting, isValid])

  return (
    <Button
      type="submit"
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...buttonProps}
      disabled={disabled}
    >
      {props.children}
      {props.isSubmitting && (
        <LoaderCircleIcon className="ml-2 size-4 animate-spin" />
      )}
    </Button>
  )
})

SubmitButton.displayName = 'SubmitButton'
