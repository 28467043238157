import { useEffect, useState } from 'react'

export const useTouchSequenceDetector = (
  requiredSequence: number[] = [1, 2, 3, 4],
  onSequenceComplete?: () => void,
  maxIntervalBetweenTouches: number = 800 // Default max interval between touches set to 800ms
) => {
  const [touchSequence, setTouchSequence] = useState<number[]>([])
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const determineQuadrant = (x: number, y: number): number => {
    const width = window.innerWidth
    const height = window.innerHeight
    const isTop = y < height / 2
    const isLeft = x < width / 2

    if (isTop && isLeft) return 1
    if (isTop && !isLeft) return 2
    if (!isTop && isLeft) return 3
    if (!isTop && !isLeft) return 4
    return 0
  }

  useEffect(() => {
    const handleTouchStart = (event: MouseEvent) => {
      const quadrant = determineQuadrant(event.clientX, event.clientY)

      // Clear existing timeout to reset sequence
      if (timeoutId) clearTimeout(timeoutId)

      // Update the sequence with the current touch
      setTouchSequence((prevSequence) => [...prevSequence, quadrant])

      // Set a new timeout to reset the sequence if no touch occurs within the max interval
      const newTimeoutId = setTimeout(() => {
        setTouchSequence([])
      }, maxIntervalBetweenTouches)

      setTimeoutId(newTimeoutId)
    }

    document.addEventListener('mousedown', handleTouchStart)
    return () => {
      document.removeEventListener('mousedown', handleTouchStart)
      if (timeoutId) clearTimeout(timeoutId) // Cleanup the timeout on unmount
    }
  }, [timeoutId, maxIntervalBetweenTouches])

  useEffect(() => {
    // console.log('touchSequence', touchSequence)
    if (touchSequence.length === requiredSequence.length) {
      const isCorrect = touchSequence.every(
        (val, index) => val === requiredSequence[index]
      )
      if (isCorrect) {
        onSequenceComplete?.()
      }

      // Reset the sequence after checking
      setTouchSequence([])
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [touchSequence, requiredSequence, onSequenceComplete, timeoutId])

  // No need to return anything unless you want to expose touchSequence or other states
}
