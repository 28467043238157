import { createFileRoute, useNavigate } from '@tanstack/react-router'

import { BigText } from '@/components/BigText.tsx'
import { DefaultLayout } from '@/components/layouts/DefaultLayout.tsx'
import { useSessionTimeout } from '@/routes/eliminacode/$ambulatorioId/-hooks.ts'

import goWaitingRoomVideo from '../../../assets/videos/go-waiting-room.mp4'

export const Route = createFileRoute(
  '/eliminacode/$ambulatorioId/2-go-waiting-room'
)({
  component: GoWaitingRoomStep,
})

export function GoWaitingRoomStep() {
  const params = Route.useParams()
  const navigate = useNavigate()
  const startOver = () => {
    navigate({ to: '/eliminacode/$ambulatorioId', params })
  }

  const { timeoutCounter } = useSessionTimeout({
    seconds: 20,
    postponeOnActivity: true,
  })

  return (
    <>
      <DefaultLayout.Body className="items-center" onClick={startOver}>
        <div className="flex h-full max-w-screen-xl grow justify-center gap-x-10  border-gray-300 bg-white/50 p-4">
          <div className="flex h-full items-center text-center">
            <BigText size="lg">Recati subito in sala d&apos;attesa</BigText>
          </div>
          <div className="h-[50rem] w-full">
            <video
              className="h-full object-contain"
              src={goWaitingRoomVideo}
              autoPlay
              loop
              muted
            />
          </div>
        </div>
      </DefaultLayout.Body>

      <DefaultLayout.Footer
        backBtnLabel="Ricomincia"
        backBtnVisible={true}
        onBackBtnClick={startOver}
        timeoutCounter={timeoutCounter}
      />
    </>
  )
}
