import { createFileRoute, useNavigate } from '@tanstack/react-router'

import { ConfigForm } from '@/components/configForm.tsx'

export const Route = createFileRoute('/getting-started/3-configuration')({
  component: Configuration,
})

export default function Configuration() {
  const navigate = useNavigate()
  const handleSaved = () =>
    navigate({ to: '/getting-started/4-configuration-completed' })

  return (
    <>
      <div className="container relative flex h-full flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[500px]">
            <ConfigForm onSave={handleSaved} />
          </div>
        </div>
      </div>
    </>
  )
}
