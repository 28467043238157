import { createFileRoute, Link } from '@tanstack/react-router'
import { ArrowRightIcon } from 'lucide-react'

import { Button } from '../../components/ui/button'

export const Route = createFileRoute(
  '/getting-started/2-authentication-completed'
)({
  component: AuthenticationCompleted,
  validateSearch(search: Record<string, string>) {
    return { types: search.types as unknown as Array<string> }
  },
})

export default function AuthenticationCompleted() {
  const { types } = Route.useSearch()

  return (
    <>
      <div className="container relative flex h-full flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px]">
            <div className="space-y-2 text-center">
              <h1 className="mb-4 text-2xl font-semibold tracking-tight">
                Attivazione Completata!
              </h1>
              <Link to={'/getting-started/3-configuration'} search={{ types }}>
                <Button>
                  Configura Totem <ArrowRightIcon className="ml-2 size-4" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
