import { queryOptions, useQuery } from '@tanstack/react-query'

import { apiClient, HcFetchError } from '../apiClient'

export const licenseQueryOptions = () =>
  queryOptions({
    queryKey: ['license', 'details'],
    queryFn: async () =>
      apiClient.api.licenses.details.$get().then((res) => res.json()),
    retry: (_failureCount, error: HcFetchError) => {
      const shouldRetry = error.statusCode !== 401

      return shouldRetry
    },
  })

export const useLicense = (
  options?: Partial<ReturnType<typeof licenseQueryOptions>>
) =>
  useQuery({
    ...licenseQueryOptions(),
    ...options,
  })
