import React, { useMemo } from 'react'
import { useRouter } from '@tanstack/react-router'
import { ChevronLeft } from 'lucide-react'

import { cn } from '@/lib/utils'
import { useTimeoutCounter } from '@/hooks/useTimeoutCounter.ts'
import { SelectionButton } from '@/components/SelectionButton.tsx'
import { Spinner } from '@/components/Spinner.tsx'

export const DefaultLayout: React.FC<{
  children: React.ReactNode
  isLoading?: boolean
  className?: string
}> & {
  Header: typeof Header
  Body: typeof Body
  Footer: typeof Footer
} = ({ children, isLoading, className }) => {
  // const childrenArray = useMemo(
  //   () => React.Children.toArray(children),
  //   [children]
  // )
  // const header = useMemo(
  //   () =>
  //     childrenArray.find(
  //       (child) => React.isValidElement(child) && child.type === Header
  //     ),
  //   [childrenArray]
  // )
  // const body = useMemo(
  //   () =>
  //     childrenArray.find(
  //       (child) => React.isValidElement(child) && child.type === Body
  //     ),
  //   [childrenArray]
  // )
  // const footer = useMemo(
  //   () =>
  //     childrenArray.find(
  //       (child) => React.isValidElement(child) && child.type === Footer
  //     ),
  //   [childrenArray]
  // )

  return (
    <div
      className={cn(
        'mx-auto flex size-full flex-col justify-center p-10',
        className
      )}
    >
      {isLoading && <Spinner />}
      {!isLoading && children}
      {/*{header}*/}
      {/*{body}*/}
      {/*{footer}*/}
    </div>
  )
}

const Header: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className }) => (
  <div className={cn('mb-10 flex shrink grow-0 flex-col', className)}>
    {children}
  </div>
)

const Body: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & { isLoading?: boolean }
> = ({ children, className, isLoading, ...rest }) => (
  <div {...rest} className={cn('flex grow flex-col ', className)}>
    {isLoading && <Spinner />}
    {!isLoading && children}
  </div>
)

const Footer: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    backBtnVisible?: boolean
    backBtnLabel?: React.ReactNode
    onBackBtnClick?: () => void
    timeoutCounter?: ReturnType<typeof useTimeoutCounter>
  }
> = ({
  children,
  className,
  backBtnVisible,
  backBtnLabel = 'Torna indietro',
  onBackBtnClick,
  timeoutCounter,
}) => {
  const router = useRouter()
  const percentTimeElapsed = useMemo(() => {
    if (!timeoutCounter?.isActive || !timeoutCounter.options?.seconds) {
      return null
    }

    return (
      100 - (timeoutCounter.secondsLeft / timeoutCounter.options?.seconds) * 100
    )
  }, [
    timeoutCounter?.isActive,
    timeoutCounter?.options?.seconds,
    timeoutCounter?.secondsLeft,
  ])

  return (
    <>
      <div className={cn('mt-10', className)}>
        {backBtnVisible && (
          <div>
            <SelectionButton
              // className="h-auto bg-gradient-to-r px-4 py-6 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
              className="flex-row items-center"
              variant="danger"
              size="md"
              onClick={() => (onBackBtnClick ?? router.history.back)()}
            >
              <ChevronLeft className="-ml-2 mr-1 size-10" size={36} />{' '}
              {backBtnLabel}
            </SelectionButton>
          </div>
        )}

        {children}
      </div>

      {timeoutCounter?.isActive && (
        <div className="relative mt-2 h-2.5 rounded-full bg-gray-200 opacity-80">
          <div className=" h-2.5 w-[100%] rounded-full bg-gradient-to-r from-red-500 from-10% via-sky-500 via-80% to-blue-500 opacity-80"></div>
          <div
            className="absolute right-0 top-0 z-10 h-2.5 rounded-r-full bg-gray-200 transition-all duration-1000 ease-linear"
            style={{ width: `${percentTimeElapsed}%` }}
          />
        </div>
      )}
    </>
  )
}

DefaultLayout.Header = Header
DefaultLayout.Body = Body
DefaultLayout.Footer = Footer
